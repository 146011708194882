import { default as index9exm9X85MwMeta } from "/var/www/histrf.ru/histrf-client/pages/about/index.vue?macro=true";
import { default as _91slug_93C1mnI06ObVMeta } from "/var/www/histrf.ru/histrf-client/pages/authors/[slug].vue?macro=true";
import { default as indexS00J5R2McLMeta } from "/var/www/histrf.ru/histrf-client/pages/authors/index.vue?macro=true";
import { default as _91slug_93NpC1XrgeSbMeta } from "/var/www/histrf.ru/histrf-client/pages/collections/[slug].vue?macro=true";
import { default as indexZDTb94kwnTMeta } from "/var/www/histrf.ru/histrf-client/pages/collections/index.vue?macro=true";
import { default as indexejEP19p8mDMeta } from "/var/www/histrf.ru/histrf-client/pages/destination/[paragraph]/index.vue?macro=true";
import { default as _91slug_93TlsjNtKGa4Meta } from "/var/www/histrf.ru/histrf-client/pages/destination/[paragraph]/region_article/[slug].vue?macro=true";
import { default as _91slug_93ScedoOJlJsMeta } from "/var/www/histrf.ru/histrf-client/pages/destination/[paragraph]/region_heraldic/[slug].vue?macro=true";
import { default as _91slug_931I53LV98GqMeta } from "/var/www/histrf.ru/histrf-client/pages/destination/[paragraph]/region_route/[slug].vue?macro=true";
import { default as _91paragraph_93kR00t0Og14Meta } from "/var/www/histrf.ru/histrf-client/pages/destination/[paragraph].vue?macro=true";
import { default as index0EplXUTKpOMeta } from "/var/www/histrf.ru/histrf-client/pages/destination/index.vue?macro=true";
import { default as destination5pQBYcoTc7Meta } from "/var/www/histrf.ru/histrf-client/pages/destination.vue?macro=true";
import { default as eventsc0Eo5QgPxbMeta } from "/var/www/histrf.ru/histrf-client/pages/events.vue?macro=true";
import { default as gpt_45chati1T7vT0yUpMeta } from "/var/www/histrf.ru/histrf-client/pages/gpt-chat.vue?macro=true";
import { default as indexANtlGcyImeMeta } from "/var/www/histrf.ru/histrf-client/pages/index.vue?macro=true";
import { default as libraryfKmzJ5o8J7Meta } from "/var/www/histrf.ru/histrf-client/pages/library.vue?macro=true";
import { default as _91slug_93IcY69AEJYbMeta } from "/var/www/histrf.ru/histrf-client/pages/listen/courses/[slug].vue?macro=true";
import { default as indexeDnZGPsBdhMeta } from "/var/www/histrf.ru/histrf-client/pages/listen/courses/index.vue?macro=true";
import { default as _91slug_93DwziwKm976Meta } from "/var/www/histrf.ru/histrf-client/pages/listen/lectures/[slug].vue?macro=true";
import { default as indexPJQLvNE1KVMeta } from "/var/www/histrf.ru/histrf-client/pages/listen/lectures/index.vue?macro=true";
import { default as _91slug_93PLa3Deuh74Meta } from "/var/www/histrf.ru/histrf-client/pages/magazine/article/[slug].vue?macro=true";
import { default as _91slug_93r5WqFwWOhzMeta } from "/var/www/histrf.ru/histrf-client/pages/magazine/release/[slug].vue?macro=true";
import { default as myhistoryFvKKUKkiFbMeta } from "/var/www/histrf.ru/histrf-client/pages/myhistory.vue?macro=true";
import { default as indexsMIJSnnteuMeta } from "/var/www/histrf.ru/histrf-client/pages/presentation/index.vue?macro=true";
import { default as bookmarksSL2JEcqTXSMeta } from "/var/www/histrf.ru/histrf-client/pages/profile/bookmarks.vue?macro=true";
import { default as indexlIE0pTOeoRMeta } from "/var/www/histrf.ru/histrf-client/pages/profile/index.vue?macro=true";
import { default as results2imrxUwujyMeta } from "/var/www/histrf.ru/histrf-client/pages/profile/results.vue?macro=true";
import { default as indexPstRIXAS3UMeta } from "/var/www/histrf.ru/histrf-client/pages/projects/index.vue?macro=true";
import { default as _91slug_93CImgZNSyNRMeta } from "/var/www/histrf.ru/histrf-client/pages/read/articles/[slug].vue?macro=true";
import { default as indexzlt8RWgPQ1Meta } from "/var/www/histrf.ru/histrf-client/pages/read/articles/index.vue?macro=true";
import { default as _91slug_93HyjpCBqUWzMeta } from "/var/www/histrf.ru/histrf-client/pages/read/biographies/[slug].vue?macro=true";
import { default as indexOHqZa497woMeta } from "/var/www/histrf.ru/histrf-client/pages/read/biographies/index.vue?macro=true";
import { default as _91document_93ivXNiFYxZpMeta } from "/var/www/histrf.ru/histrf-client/pages/read/documents/[slug]/[document].vue?macro=true";
import { default as indexzLmgTtmZTgMeta } from "/var/www/histrf.ru/histrf-client/pages/read/documents/[slug]/index.vue?macro=true";
import { default as indexAmSkZF4QMkMeta } from "/var/www/histrf.ru/histrf-client/pages/read/documents/index.vue?macro=true";
import { default as _91slug_93nFFDn8QuvfMeta } from "/var/www/histrf.ru/histrf-client/pages/read/monuments/[slug].vue?macro=true";
import { default as indexqpsUJ6EH8IMeta } from "/var/www/histrf.ru/histrf-client/pages/read/monuments/index.vue?macro=true";
import { default as _91slug_93qHEgkSkDLNMeta } from "/var/www/histrf.ru/histrf-client/pages/read/news/[slug].vue?macro=true";
import { default as indexBwrlrk9ZkaMeta } from "/var/www/histrf.ru/histrf-client/pages/read/news/index.vue?macro=true";
import { default as _91slug_938EBw5LvzRVMeta } from "/var/www/histrf.ru/histrf-client/pages/read/science/[slug].vue?macro=true";
import { default as indexLRTiEtwbpLMeta } from "/var/www/histrf.ru/histrf-client/pages/read/science/index.vue?macro=true";
import { default as searchFQu7wcswlCMeta } from "/var/www/histrf.ru/histrf-client/pages/search.vue?macro=true";
import { default as summeryJesiFrFw6Meta } from "/var/www/histrf.ru/histrf-client/pages/summer.vue?macro=true";
import { default as _91slug_93qAhfWHXOS9Meta } from "/var/www/histrf.ru/histrf-client/pages/tags/[slug].vue?macro=true";
import { default as _91chapter_93z9HlzCAVE6Meta } from "/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/[chapter].vue?macro=true";
import { default as _91slug_93SQKuYffUPCMeta } from "/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/article/[slug].vue?macro=true";
import { default as _91slug_93UCTzsKeB0sMeta } from "/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/audiolecture/[slug].vue?macro=true";
import { default as _91slug_93p7g8Gqizy5Meta } from "/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/chapter/[slug].vue?macro=true";
import { default as indexhy1BpDxsbHMeta } from "/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/index.vue?macro=true";
import { default as _91slug_93q7sPLHvxQGMeta } from "/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/test/[slug].vue?macro=true";
import { default as _91slug_934hBIuMLbo5Meta } from "/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/videolecture/[slug].vue?macro=true";
import { default as _91category_931BEStZYm7GMeta } from "/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category].vue?macro=true";
import { default as indexquJgHOmmsnMeta } from "/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/index.vue?macro=true";
import { default as _91parent_939fBzmwRNtMMeta } from "/var/www/histrf.ru/histrf-client/pages/teacher/[parent].vue?macro=true";
import { default as _91slug_936roaMmuryvMeta } from "/var/www/histrf.ru/histrf-client/pages/teacher/glossary/[slug].vue?macro=true";
import { default as indexHDhWMrjJdeMeta } from "/var/www/histrf.ru/histrf-client/pages/teacher/index.vue?macro=true";
import { default as teacherUB6iwRkQKGMeta } from "/var/www/histrf.ru/histrf-client/pages/teacher.vue?macro=true";
import { default as _91slug_93ju2UkvyFrEMeta } from "/var/www/histrf.ru/histrf-client/pages/tests/[slug].vue?macro=true";
import { default as indexFEeZgJQTxdMeta } from "/var/www/histrf.ru/histrf-client/pages/tests/index.vue?macro=true";
import { default as indexZkrUNMaXcVMeta } from "/var/www/histrf.ru/histrf-client/pages/timeline/index.vue?macro=true";
import { default as victory_45day2gma0fmfmzMeta } from "/var/www/histrf.ru/histrf-client/pages/victory-day.vue?macro=true";
import { default as _91slug_93jTmz9LA7ToMeta } from "/var/www/histrf.ru/histrf-client/pages/watch/courses/[slug].vue?macro=true";
import { default as indexvCnS6jwcgMMeta } from "/var/www/histrf.ru/histrf-client/pages/watch/courses/index.vue?macro=true";
import { default as _91slug_93a7VWEasdiPMeta } from "/var/www/histrf.ru/histrf-client/pages/watch/films/[slug].vue?macro=true";
import { default as indexMbrvCBAX8TMeta } from "/var/www/histrf.ru/histrf-client/pages/watch/films/index.vue?macro=true";
import { default as _91slug_93OkOTmaUxi8Meta } from "/var/www/histrf.ru/histrf-client/pages/watch/lectures/[slug].vue?macro=true";
import { default as indextOers87xhOMeta } from "/var/www/histrf.ru/histrf-client/pages/watch/lectures/index.vue?macro=true";
import { default as indexrqgRG4Y8S3Meta } from "/var/www/histrf.ru/histrf-client/pages/watch/memdates/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/about/index.vue")
  },
  {
    name: "authors-slug",
    path: "/authors/:slug()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/authors/[slug].vue")
  },
  {
    name: "authors",
    path: "/authors",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/authors/index.vue")
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    meta: _91slug_93NpC1XrgeSbMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/collections/[slug].vue")
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/collections/index.vue")
  },
  {
    name: destination5pQBYcoTc7Meta?.name,
    path: "/destination",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/destination.vue"),
    children: [
  {
    name: _91paragraph_93kR00t0Og14Meta?.name,
    path: ":paragraph()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/destination/[paragraph].vue"),
    children: [
  {
    name: "destination-paragraph",
    path: "",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/destination/[paragraph]/index.vue")
  },
  {
    name: "destination-paragraph-region_article-slug",
    path: "region_article/:slug()",
    meta: _91slug_93TlsjNtKGa4Meta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/destination/[paragraph]/region_article/[slug].vue")
  },
  {
    name: "destination-paragraph-region_heraldic-slug",
    path: "region_heraldic/:slug()",
    meta: _91slug_93ScedoOJlJsMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/destination/[paragraph]/region_heraldic/[slug].vue")
  },
  {
    name: "destination-paragraph-region_route-slug",
    path: "region_route/:slug()",
    meta: _91slug_931I53LV98GqMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/destination/[paragraph]/region_route/[slug].vue")
  }
]
  },
  {
    name: "destination",
    path: "",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/destination/index.vue")
  }
]
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/events.vue")
  },
  {
    name: "gpt-chat",
    path: "/gpt-chat",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/gpt-chat.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexANtlGcyImeMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/index.vue")
  },
  {
    name: "library",
    path: "/library",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/library.vue")
  },
  {
    name: "listen-courses-slug",
    path: "/listen/courses/:slug()",
    meta: _91slug_93IcY69AEJYbMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/listen/courses/[slug].vue")
  },
  {
    name: "listen-courses",
    path: "/listen/courses",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/listen/courses/index.vue")
  },
  {
    name: "listen-lectures-slug",
    path: "/listen/lectures/:slug()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/listen/lectures/[slug].vue")
  },
  {
    name: "listen-lectures",
    path: "/listen/lectures",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/listen/lectures/index.vue")
  },
  {
    name: "magazine-article-slug",
    path: "/magazine/article/:slug()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/magazine/article/[slug].vue")
  },
  {
    name: "magazine-release-slug",
    path: "/magazine/release/:slug()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/magazine/release/[slug].vue")
  },
  {
    name: "myhistory",
    path: "/myhistory",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/myhistory.vue")
  },
  {
    name: "presentation",
    path: "/presentation",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/presentation/index.vue")
  },
  {
    name: "profile-bookmarks",
    path: "/profile/bookmarks",
    meta: bookmarksSL2JEcqTXSMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/profile/bookmarks.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexlIE0pTOeoRMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/profile/index.vue")
  },
  {
    name: "profile-results",
    path: "/profile/results",
    meta: results2imrxUwujyMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/profile/results.vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/projects/index.vue")
  },
  {
    name: "read-articles-slug",
    path: "/read/articles/:slug()",
    meta: _91slug_93CImgZNSyNRMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/articles/[slug].vue")
  },
  {
    name: "read-articles",
    path: "/read/articles",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/articles/index.vue")
  },
  {
    name: "read-biographies-slug",
    path: "/read/biographies/:slug()",
    meta: _91slug_93HyjpCBqUWzMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/biographies/[slug].vue")
  },
  {
    name: "read-biographies",
    path: "/read/biographies",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/biographies/index.vue")
  },
  {
    name: "read-documents-slug-document",
    path: "/read/documents/:slug()/:document()",
    meta: _91document_93ivXNiFYxZpMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/documents/[slug]/[document].vue")
  },
  {
    name: "read-documents-slug",
    path: "/read/documents/:slug()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/documents/[slug]/index.vue")
  },
  {
    name: "read-documents",
    path: "/read/documents",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/documents/index.vue")
  },
  {
    name: "read-monuments-slug",
    path: "/read/monuments/:slug()",
    meta: _91slug_93nFFDn8QuvfMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/monuments/[slug].vue")
  },
  {
    name: "read-monuments",
    path: "/read/monuments",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/monuments/index.vue")
  },
  {
    name: "read-news-slug",
    path: "/read/news/:slug()",
    meta: _91slug_93qHEgkSkDLNMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/news/[slug].vue")
  },
  {
    name: "read-news",
    path: "/read/news",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/news/index.vue")
  },
  {
    name: "read-science-slug",
    path: "/read/science/:slug()",
    meta: _91slug_938EBw5LvzRVMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/science/[slug].vue")
  },
  {
    name: "read-science",
    path: "/read/science",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/read/science/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchFQu7wcswlCMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/search.vue")
  },
  {
    name: "summer",
    path: "/summer",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/summer.vue")
  },
  {
    name: "tags-slug",
    path: "/tags/:slug()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/tags/[slug].vue")
  },
  {
    name: teacherUB6iwRkQKGMeta?.name,
    path: "/teacher",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher.vue"),
    children: [
  {
    name: _91parent_939fBzmwRNtMMeta?.name,
    path: ":parent()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/[parent].vue"),
    children: [
  {
    name: _91category_931BEStZYm7GMeta?.name,
    path: ":category()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category].vue"),
    children: [
  {
    name: "teacher-parent-category-chapter",
    path: ":chapter()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/[chapter].vue")
  },
  {
    name: "teacher-parent-category-article-slug",
    path: "article/:slug()",
    meta: _91slug_93SQKuYffUPCMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/article/[slug].vue")
  },
  {
    name: "teacher-parent-category-audiolecture-slug",
    path: "audiolecture/:slug()",
    meta: _91slug_93UCTzsKeB0sMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/audiolecture/[slug].vue")
  },
  {
    name: "teacher-parent-category-chapter-slug",
    path: "chapter/:slug()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/chapter/[slug].vue")
  },
  {
    name: "teacher-parent-category",
    path: "",
    meta: indexhy1BpDxsbHMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/index.vue")
  },
  {
    name: "teacher-parent-category-test-slug",
    path: "test/:slug()",
    meta: _91slug_93q7sPLHvxQGMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/test/[slug].vue")
  },
  {
    name: "teacher-parent-category-videolecture-slug",
    path: "videolecture/:slug()",
    meta: _91slug_934hBIuMLbo5Meta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/[category]/videolecture/[slug].vue")
  }
]
  },
  {
    name: "teacher-parent",
    path: "",
    meta: indexquJgHOmmsnMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/[parent]/index.vue")
  }
]
  },
  {
    name: "teacher-glossary-slug",
    path: "glossary/:slug()",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/glossary/[slug].vue")
  },
  {
    name: "teacher",
    path: "",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/teacher/index.vue")
  }
]
  },
  {
    name: "tests-slug",
    path: "/tests/:slug()",
    meta: _91slug_93ju2UkvyFrEMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/tests/[slug].vue")
  },
  {
    name: "tests",
    path: "/tests",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/tests/index.vue")
  },
  {
    name: "timeline",
    path: "/timeline",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/timeline/index.vue")
  },
  {
    name: "victory-day",
    path: "/victory-day",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/victory-day.vue")
  },
  {
    name: "watch-courses-slug",
    path: "/watch/courses/:slug()",
    meta: _91slug_93jTmz9LA7ToMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/watch/courses/[slug].vue")
  },
  {
    name: "watch-courses",
    path: "/watch/courses",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/watch/courses/index.vue")
  },
  {
    name: "watch-films-slug",
    path: "/watch/films/:slug()",
    meta: _91slug_93a7VWEasdiPMeta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/watch/films/[slug].vue")
  },
  {
    name: "watch-films",
    path: "/watch/films",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/watch/films/index.vue")
  },
  {
    name: "watch-lectures-slug",
    path: "/watch/lectures/:slug()",
    meta: _91slug_93OkOTmaUxi8Meta || {},
    component: () => import("/var/www/histrf.ru/histrf-client/pages/watch/lectures/[slug].vue")
  },
  {
    name: "watch-lectures",
    path: "/watch/lectures",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/watch/lectures/index.vue")
  },
  {
    name: "watch-memdates",
    path: "/watch/memdates",
    component: () => import("/var/www/histrf.ru/histrf-client/pages/watch/memdates/index.vue")
  }
]